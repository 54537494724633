<template>
    <div>
        <v-slide-y-reverse-transition>
            <v-alert
                v-if="!authorizations.sb_484_verified"
                border="top"
                color="accent"
                dark
                icon="mdi-magnify"
                prominent
                >{{ $t("applications.online-app-sb-484") }}</v-alert
            >
        </v-slide-y-reverse-transition>

        <v-slide-y-reverse-transition>
            <v-alert
                v-if="
                    authorizations.sb_484_verified &&
                    authorizations.sb_484_passes
                "
                border="top"
                dark
                color="success darken-2"
                icon="mdi-check-bold"
                prominent
                >{{ $t("applications.online-app-screen-success") }}</v-alert
            >
        </v-slide-y-reverse-transition>

        <v-alert
            v-if="
                authorizations.sb_484_verified && !authorizations.sb_484_passes
            "
            border="top"
            dark
            color="error"
            icon="mdi-alert"
            prominent
            >{{ $t("applications.online-app-screen-fail") }}</v-alert
        >
    </div>
</template>
<script>
export default {
    name: "verify-screening-notices",
    components: {},
    props: {
        authorizations: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>