var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-slide-y-reverse-transition",
        [
          !_vm.authorizations.sb_484_verified
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "top",
                    color: "accent",
                    dark: "",
                    icon: "mdi-magnify",
                    prominent: ""
                  }
                },
                [_vm._v(_vm._s(_vm.$t("applications.online-app-sb-484")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-slide-y-reverse-transition",
        [
          _vm.authorizations.sb_484_verified && _vm.authorizations.sb_484_passes
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "top",
                    dark: "",
                    color: "success darken-2",
                    icon: "mdi-check-bold",
                    prominent: ""
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("applications.online-app-screen-success"))
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.authorizations.sb_484_verified && !_vm.authorizations.sb_484_passes
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "top",
                dark: "",
                color: "error",
                icon: "mdi-alert",
                prominent: ""
              }
            },
            [_vm._v(_vm._s(_vm.$t("applications.online-app-screen-fail")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }